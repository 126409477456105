import { OKTA_SCOPES } from './environment.config';
import { Environment } from './environment.model';

export const environment: Environment = {
  production: true,
  auth: {
    issuer: 'https://et-tractebel.oktapreview.com/oauth2/default',
    clientId: '0oa80viilk2ANxXGz0x7',
    redirectUri: window.location.origin + '/login/callback',
    postLogoutRedirectUri: window.location.origin + '/',
    scopes: OKTA_SCOPES,
    storageManager: {
      token: {
        storageType: 'cookie',
        sameSite: 'strict',
        secure: true,
        sessionCookie: true,
      },
    },
  },
  api: {
    url: 'https://qa.hyppo.te-ded.com/api',
  },
  websocket: {
    url: 'wss://qa.hyppo.te-ded.com',
  },
  sentry: {
    dsn: 'https://92f9d5802915c1f7d01e89d7a35e643a@o258615.ingest.sentry.io/4506340999364608',
  },
  trainingLink:
    'https://engie.sharepoint.com/:u:/r/sites/TETDataViz/Shared Documents/HyppoTraining/QA/index.aspx',
};
